'use client';
import { RelayEnvironmentProvider } from 'react-relay';

import { getCurrentEnvironment } from './environment';

export function RelayEnvironmentProviderWrapper({
  children,
  clientHttpEndpoint,
  userKratosId,
}: {
  children: React.ReactNode;
  clientHttpEndpoint: string;
  userKratosId?: string | null;
}): React.JSX.Element {
  const environment = getCurrentEnvironment({
    clientHttpEndpoint,
    headers: userKratosId
      ? {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'x-user': userKratosId,
        }
      : undefined,
  });
  return (
    <RelayEnvironmentProvider environment={environment}>
      {children}
    </RelayEnvironmentProvider>
  );
}
